export var Heading = {
  variants: {
    page: {
      fontSize: 60,
      fontWeight: 'normal',
      lineHeight: '82px',
      letterSpacing: '-0.02em'
    },
    pageSubHeader: {
      fontSize: 10,
      fontWeight: 'normal',
      lineHeight: '24px',
      letterSpacing: '-0.02em'
    },
    homepage: {
      fontSize: 40,
      lineHeight: '45px',
      fontWeight: 'normal',
      textTransform: 'uppercase'
    },
    footer: {
      fontSize: 11,
      fontWeight: 'normal',
      lineHeight: '17px',
      textTransform: 'capitalize'
    },
    h1: {
      fontSize: '40px',
      fontWeight: 'bold',
      lineHeight: '45px'
    },
    h2: {
      fontSize: '28px',
      fontWeight: 'bold',
      lineHeight: '34px'
    },
    h3: {
      fontSize: '24px'
    },
    "static": {
      color: 'brand.primary.500',
      fontSize: '3xl'
    }
  },
  sizes: {
    h1: {
      fontSize: {
        base: '5xl',
        sm: '6xl'
      },
      lineHeight: {
        base: '5xl',
        sm: '6xl'
      },
      letterSpacing: 'tightest',
      fontWeight: 'black'
    },
    h2: {
      fontSize: {
        base: '4xl',
        sm: '5xl'
      },
      lineHeight: {
        base: '4xl',
        sm: '5xl'
      },
      letterSpacing: 'tighter',
      fontWeight: 'black'
    },
    h3: {
      fontSize: {
        base: '3xl',
        sm: '4xl'
      },
      lineHeight: {
        base: '3xl',
        sm: '4xl'
      },
      letterSpacing: {
        base: 'tight',
        sm: 'tighter'
      },
      fontWeight: 'black'
    },
    h4: {
      fontSize: '2xl',
      lineHeight: '2xl',
      letterSpacing: 'tight',
      fontWeight: 'semibold'
    },
    h5: {
      fontSize: 'xl',
      lineHeight: 'xl',
      letterSpacing: 'tight',
      fontWeight: 'bold'
    }
  }
};