// https://chakra-ui.com/docs/styled-system/theming/customize-theme
import { extendTheme } from '@chakra-ui/react';
import { Accordion } from './components/accordion';
import { Button } from './components/button';
import { Heading } from './components/heading';
import { Input } from './components/input';
import { Link } from './components/link';
import { List } from './components/list';
import { Modal } from './components/modal';
import { Text } from './components/text';
import { Tooltip } from './components/tooltip';
import { colors } from './foundations/colors';
import { layerStyles } from './foundations/layer-styles';
import { sizes } from './foundations/sizes';
import { fonts, fontSizes, letterSpacings, lineHeights } from './foundations/typography';
export var theme = extendTheme({
  colors: colors,
  sizes: sizes,
  fonts: fonts,
  fontSizes: fontSizes,
  letterSpacings: letterSpacings,
  lineHeights: lineHeights,
  layerStyles: layerStyles,
  components: {
    Accordion: Accordion,
    Button: Button,
    Heading: Heading,
    Input: Input,
    Link: Link,
    List: List,
    Modal: Modal,
    Text: Text,
    Tooltip: Tooltip
  }
});