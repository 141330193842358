export var colors = {
  black: '#000000',
  brand: {
    primary: {
      '50': '#E5EDFF',
      '100': '#B8CCFF',
      '200': '#8AABFF',
      '300': '#5C8BFF',
      '400': '#7184FF',
      '500': '#0049FF',
      '600': '#003ACC',
      '700': '#002C99',
      '800': '#001D66',
      '900': '#000F33'
    },
    gray: {
      '25': '#FCFCFD',
      '50': '#F9FAFB',
      '100': '#F2F4F7',
      '200': '#EAECF0',
      '300': '#D0D5DD',
      '400': '#98A2B3',
      '500': '#667085',
      '600': '#475467',
      '700': '#344054',
      '900': '#101828'
    },
    success: {
      '500': '#12B76A'
    },
    error: {
      '500': '#F04438'
    }
  },
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.05)',
    100: 'rgba(255, 255, 255, 0.1)',
    200: 'rgba(255, 255, 255, 0.2)',
    300: 'rgba(255, 255, 255, 0.3)',
    400: 'rgba(255, 255, 255, 0.4)',
    500: 'rgba(255, 255, 255, 0.5)',
    600: 'rgba(255, 255, 255, 0.6)',
    700: 'rgba(255, 255, 255, 0.7)',
    800: 'rgba(255, 255, 255, 0.8)',
    900: 'rgba(255, 255, 255, 0.9)'
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.05)',
    100: 'rgba(0, 0, 0, 0.1)',
    200: 'rgba(0, 0, 0, 0.2)',
    300: 'rgba(0, 0, 0, 0.3)',
    400: 'rgba(0, 0, 0, 0.4)',
    500: 'rgba(0, 0, 0, 0.5)',
    600: 'rgba(0, 0, 0, 0.6)',
    700: 'rgba(0, 0, 0, 0.7)',
    800: 'rgba(0, 0, 0, 0.8)',
    900: 'rgba(0, 0, 0, 0.9)'
  }
};