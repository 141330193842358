var republicFont = "'Inter var', 'Inter', 'Helvetica Neue', Arial, sans-serif";
export var fonts = {
  heading: republicFont,
  body: republicFont
};
export var letterSpacings = {
  tightest: '-0.04em',
  tighter: '-0.03em',
  tight: '-0.02em',
  'less-tight': '-0.01em',
  normal: '0',
  wide: '0.02em',
  wider: '0.03em',
  widest: '0.04em'
};
export var lineHeights = {
  normal: 'normal',
  '6xl': '4rem',
  '5xl': '3.125rem',
  '4xl': '3rem',
  '3xl': '2.5rem',
  '2xl': '2.125rem',
  xl: '1.875rem',
  lg: '1.625rem',
  md: '1.5rem',
  sm: '1rem',
  xs: '0.75rem',
  none: 1,
  tinier: 1.125,
  tiny: 1.2,
  shorter: 1.25,
  "short": 1.375,
  base: 1.5,
  tall: 1.625,
  taller: 1.725
};
export var fontSizes = {
  '8': '8px',
  '10': '10px',
  '11': '11px',
  '12': '12px',
  '13': '13px',
  '14': '14px',
  '16': '16px',
  '25': '25px',
  '30': '30px',
  '40': '40px',
  '50': '50px',
  '60': '60px',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem'
};