var baseStyle = {
  _focus: {
    boxShadow: 'none'
  }
};
export var Link = {
  baseStyle: baseStyle,
  variants: {
    "static": {
      color: 'blue.500',
      _active: {
        color: 'red.500'
      },
      _visited: {
        color: 'purple'
      }
    }
  }
};