/* eslint-disable filename-rules/match */

import '../global.css';

import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';

import {
  ErrorBoundary,
  PageErrorMessage,
} from '@r-client/republic-capital/feature/errors';
import { theme } from '@r-client/republic-capital/ui/theme';
import { GqlClientProvider } from '@r-client/shared/data/client';
import {
  createReportingConfig,
  ErrorReportingProvider,
  NavigationContextHandler,
  useReporting,
} from '@r-client/shared/data/error-reporting';
import { AuthProvider } from '@r-client/shared/feature/auth';
import { ConfigProvider } from '@r-client/shared/util/core';

import { getPublicConfig } from '../application.config';
import { useGqlManager } from '../gql-client';

const publicConfig = getPublicConfig();

const _reportingConfig = createReportingConfig({
  accessToken: publicConfig.rollbarClientToken,
  codeVersion: publicConfig.applicationVersion,
  environment: publicConfig.applicationEnvironment,
  serverHost: publicConfig.graphqlEndpointUrl,
  kind: 'rollbar',
});

function CustomApp({ Component, pageProps }: AppProps) {
  const gqlClient = useGqlManager(pageProps);
  const reporting = useReporting();

  return (
    <GqlClientProvider client={gqlClient} reporting={reporting}>
      <NavigationContextHandler useRouter={useRouter} />
      <ErrorBoundary fallbackUI={PageErrorMessage}>
        <ConfigProvider value={publicConfig}>
          <AuthProvider>
            <ChakraProvider theme={theme}>
              <Script src="https://cmp.osano.com/16CPKSTMDMLf475H/a8be549f-3f67-46ab-985b-1585925c7f4c/osano.js" />
              <Component {...pageProps} />
            </ChakraProvider>
          </AuthProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </GqlClientProvider>
  );
}

function AppWithErrorReporting(props: AppProps) {
  return (
    <ErrorReportingProvider config={_reportingConfig}>
      <CustomApp {...props} />
    </ErrorReportingProvider>
  );
}

export default AppWithErrorReporting;
