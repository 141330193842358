var baseStyleCloseButton = {
  position: 'absolute',
  top: 2,
  insetEnd: 3,
  _focus: {
    boxShadow: 'none'
  }
};
var dialogContainer = {
  // This should resolve Chakra UI Modal iOS issue: https://github.com/chakra-ui/chakra-ui/issues/4680
  '@supports(height: -webkit-fill-available)': {},
  height: '100%'
};

var baseStyle = function baseStyle() {
  return {
    closeButton: baseStyleCloseButton,
    dialogContainer: dialogContainer
  };
};

export var Modal = {
  baseStyle: baseStyle
};