/* eslint-disable no-process-env */
import {
  IGqlClientConfig,
  LOCAL_GRAPHQL_ENDPOINT,
} from '@r-client/shared/data/client';
import { IPublicConfig, TAuthPersistence } from '@r-client/shared/util/core';

export const getPublicConfig = (): IPublicConfig => {
  if (process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT === undefined) {
    throw new Error(
      'process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT not found. Please provide NEXT_PUBLIC_GRAPHQL_ENDPOINT in env.'
    );
  }

  return {
    graphqlEndpointUrl:
      process.env.NODE_ENV === 'development' && typeof window === 'object'
        ? LOCAL_GRAPHQL_ENDPOINT
        : process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    applicationEnvironment:
      (process.env.NEXT_PUBLIC_APP_ENV as 'staging') || 'production',
    applicationVersion: process.env.NEXT_PUBLIC_APP_VERSION || 'no-version-set',
    rollbarClientToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    applicationURLOrigin: process.env.NEXT_PUBLIC_URL_ORIGIN || '',
    authPersistence: { type: 'localStorage' } as TAuthPersistence,
  };
};

export const apiClientConfig: IGqlClientConfig = {
  apiAuthHeader: process.env.NEXT_PUBLIC_GRAPHQL_AUTH,
  apiUri: getPublicConfig().graphqlEndpointUrl,
  clientName: 'republic-capital-web',
  clientVersion: process.env.NEXT_PUBLIC_APP_VERSION || 'no-version',
  authPersistence: getPublicConfig().authPersistence,
};
