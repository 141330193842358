export var Accordion = {
  variants: {
    primary: {
      container: {
        bg: 'transparent',
        borderTop: '0.5px solid white',
        py: '25px'
      },
      button: {
        p: 0,
        paddingRight: 6,
        fontWeight: 500
      },
      panel: {
        p: 0,
        mt: 2
      }
    }
  },
  sizes: {
    sm: {
      button: {
        fontSize: '20px',
        line: '30px'
      },
      panel: {
        fontSize: '15px',
        lineHeight: '20px'
      }
    },
    md: {
      button: {
        fontSize: '25px',
        line: '20px'
      },
      panel: {
        fontSize: '15px',
        lineHeight: '20px'
      }
    }
  }
};