import { NormalizedCacheObject } from '@apollo/client';

import {
  createGqlClient as createGqlClientInternal,
  useGqlManager as useGqlManagerInternal,
} from '@r-client/shared/data/client';

import { apiClientConfig } from './application.config';

export const createGqlClient = (initialState?: NormalizedCacheObject) =>
  createGqlClientInternal({
    initialState,
    config: apiClientConfig,
  });

export const useGqlManager = (pageProps: unknown) =>
  useGqlManagerInternal(pageProps, apiClientConfig);
