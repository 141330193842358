import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _baseStyle;

import { cssVar } from '@chakra-ui/react';
var $arrowBg = cssVar('popper-arrow-bg');
export var Tooltip = {
  baseStyle: (_baseStyle = {
    background: 'white',
    boxShadow: 'lg'
  }, _defineProperty(_baseStyle, $arrowBg.variable, 'white'), _defineProperty(_baseStyle, "color", 'brand.gray.700'), _baseStyle)
};